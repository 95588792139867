import React, { useEffect, useState } from "react";
import { Button, VerifCodeInput, Text } from "..";
import i18n from "../../i18n";
import { InputI, InputValidateI } from "../ComponentInterface";
import parse from "html-react-parser";
import { useTriggeredVerificationCodeValidate } from "../../services";
import { useContext, updateError } from "../../Context";
import { validateVerifCode } from "../validate";
import { IN_PERSON } from "../../utils/Constants";
import { addSessionStorage } from "../../utils";

const AccessPeiForm = ({ successFunc, previousRoute, errorObj, setErrorObj }: { successFunc: (param?: any) => void, previousRoute?: string, errorObj?: any, setErrorObj?: any }) => {
	const { dispatch } = useContext();
	
	const { useVerificationCodeValidate: verificationCodeValidate, isLoading } =
	useTriggeredVerificationCodeValidate();
	
	const [inputs, setInputs] = useState<InputI>({ code: "" });
	const [validInputs, setValidateInputs] = useState<InputValidateI>({
		isValidVerifCode: false,
	});

	//  IF valid Code then Call APi
	// SUbmit After Validation passed
	useEffect(() => {
        if (validInputs.isValidVerifCode) {
            verifyCodeValidate()
        }

		// eslint-disable-next-line 
	}, [validInputs.isValidVerifCode]);

	// Check the Enter Button Press
	const keyDownHandler = (event: any) => {
		if (event.key === "Enter") {
			event.preventDefault();

			handleSuccessFunc();
		}
	};

	const handleValidations = (verifCode: string) => {
		let isValid: boolean = false;
        // Required Check
		if (!verifCode) {
			isValid = false;
			setErrorObj({ ...errorObj, error: 'in-person-required-field-error', errorVerifCode: true })
		} else {
			// Regex Check for Aplpha Numeric Only
			if (!validateVerifCode(verifCode)) {	
				isValid = false;
				setErrorObj({ ...errorObj, error: 'in-person-invalid-format-field-error', errorVerifCode: true })
			}

			isValid = true;
		}

		return isValid;
    };

	const handleSuccessFunc = () => {
		const result: boolean = handleValidations(inputs?.code || "");
		setValidateInputs({ ...validInputs, isValidVerifCode: result })
    }

	// Api Call
	const verifyCodeValidate = async () => {
		try {
			const { response, error:  api_error } = await verificationCodeValidate({ data: { code : inputs?.code } });

			if (response.isSuccess) {
				successFunc!(previousRoute);

				// Add flow details in Session storage to use on other pages.
				// COntext using localstorage to using session storage
				// Add email to localstorage to use on 2fa email scrren
				localStorage.setItem('userEmail', response?.data?.email)
				addSessionStorage("createAccountFlow", JSON.stringify({ type: IN_PERSON, verification_code: inputs?.code }));
			} 
		
			if (api_error.isUnauthorizedError || api_error.isForbiddenError) { 
				dispatch(updateError(true))
			} else if (api_error.isInvalidError) {
				// Expired Code
				if (api_error?.response?.data?.error === "expired_verification_code") {
					setErrorObj({ ...errorObj, error: 'in-person-expired-code-error', errorVerifCode: true })
					return;
				}
				
				// Invalid Code 
				setErrorObj({ ...errorObj, error: 'in-person-invalid-code-error', errorVerifCode: true })
			} else {
				setErrorObj({ ...errorObj, error: 'error-500-1', errorNotification: true })
			}
		} catch (e) {
			console.log("e", e);
			setErrorObj({ ...errorObj, error: 'error-500-1', errorNotification: true })
		} finally {
			setValidateInputs({ ...validInputs, isValidVerifCode: false })
		}
	};
	
	return (
		<>
			<div className="access-form">
				<h5>{`${i18n.t("recent-access")}`}</h5>
				<p data-testid="body">{`${i18n.t("enter-code")}`}</p>
				<form
					onKeyDown={keyDownHandler}
					onSubmit={(e) => e.preventDefault()}
					className="verif-code-form"
				>
					<div className="verif-code">
						<VerifCodeInput
							label={i18n.t("verification-code")}
							onChange={(e) => {
								setInputs((prev) => ({
									...prev,
									code: e.target.value.trim(),
								}));
								setErrorObj({ error: "" });
							}}
							dataTestId="verification-code"
							maxLength={8}
							isError={errorObj?.errorVerifCode}
						/>
						{errorObj?.errorVerifCode && (
							<Text className="error">
								{parse(i18n.t(errorObj.error))}
							</Text>
						)}
						<Button
							onClick={handleSuccessFunc}
							text={i18n.t("continue")}
							isLoading={isLoading}
							dataTestId="Button-primary-in-person"
						/>
					</div>
				</form>
			</div>
		</>
	);
};

export default AccessPeiForm;
