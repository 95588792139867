import React, { useState } from 'react'
import parse from 'html-react-parser'

import { ListItem, Text } from '../../../../../components'
import i18n from '../../../../../i18n'
import { ProfileDataI } from '../../../../../components/ComponentInterface'
import UpdateSWATModal from './modal/UpdateSWATModal'

import useGetProfileParsed from '../hooks/useGetProfileParsed'

import useIdvVerification from '../../../hooks/useIdvVerification'
import { EIDVBackBtn } from '../../../../../wallet/types/wallet'

interface IAdditionalProps {
  profileData: ProfileDataI
  isVerified: boolean
  setProfileData: (data: any) => void
}

const AdditionalInformation = ({ profileData, isVerified, setProfileData }: IAdditionalProps) => {
  const [activateUpdateSWATModal, setActivateUpdateSWATModal] = useState(false)
  const { healthCardData, propertyTaxData } = useGetProfileParsed({ profileData, isVerified })

  const { handleStartIdvVerification, getIDVHealthCardLink, getIDVHealthCardUpdate } = useIdvVerification()

  const { healthCardNumber } = profileData

  const handleIdvHealthCard = async () => {
    if (isVerified) {
      if (healthCardNumber) {
        const response = await getIDVHealthCardUpdate()
        setProfileData(response)
        setActivateUpdateSWATModal(false)
      } else {
        await getIDVHealthCardLink()
      }
    } else {
      handleStartIdvVerification(isVerified, EIDVBackBtn.PROFILE)
    }
  }

  return (
    <div className="profile-information-session">
      <Text className="subtitle" dataTestId="h3-additional-info">
        {parse(i18n.t('wallet-profile-additional-information-title'))}
      </Text>
      <div className="profile-info-group">
        <ListItem
          title={i18n.t('wallet-profile-additional-information-health')}
          value={healthCardData}
          dataTestId={'row-health-card'}
          action={isVerified ? (healthCardNumber ? 'Update' : 'Add') : ''}
          hideAction={!isVerified}
          onClick={() => (isVerified ? (healthCardNumber ? setActivateUpdateSWATModal(true) : handleIdvHealthCard()) : handleIdvHealthCard())}
        />
        <ListItem
          title={i18n.t('wallet-profile-additional-information-property')}
          hideAction
          value={propertyTaxData}
          dataTestId={'row-property-tax'}
          action={'Edit'}
          onClick={() => {}}
        />
      </div>
      <UpdateSWATModal updateAction={handleIdvHealthCard} isOpen={activateUpdateSWATModal} onClose={() => setActivateUpdateSWATModal(false)} />
    </div>
  )
}

export default AdditionalInformation
