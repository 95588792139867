import React from 'react'
import parse from 'html-react-parser'

import i18n from '../../../../../i18n'
import { Button, Text } from '../../../../../components'
import { useHistory } from 'react-router-dom'
import ROUTES from '../../../../../routes/common/constants'

const AccountHistory = () => {
  const history = useHistory()

  return (
    <div className="profile-information-session">
      <div className="account-history">
        <Text className="subtitle" dataTestId="h3-acct-history">
          {parse(i18n.t('wallet-profile-account-history-title'))}
        </Text>
        <div data-testid="row-acct-history">
          <h3 className="description" data-testid="label">{parse(i18n.t('wallet-profile-account-history-lead'))}</h3>
          <div className="digital" data-testid="text">{parse(i18n.t('wallet-profile-account-history-description'))} </div>
          <Button 
            onClick={() => history.push(ROUTES.WalletConsentHistory)} 
            dataTestId="Button-secondary" 
            secondary 
            className="btn-account-history" 
            text={i18n.t('wallet-profile-account-history-review')}
          />
        </div>
      </div>
    </div>
  )
}

export default AccountHistory
