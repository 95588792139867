import React, { useState } from 'react'
import i18n from '../../i18n'
import parse from 'html-react-parser'
import { Layout, SignInForm, AccessPeiForm, Title, Notification } from '../../components'
import { PageI, errorI } from '../../components/ComponentInterface';

const SignInPage = ({ successFunc, otherRoutes }: PageI) => {
  const [errorObj, setErrorObj] = useState<errorI>({ error: '' })

  return (
    <>
      <Layout header footer title={i18n.t('sign-in-title')}>
        <Title title={i18n.t('sign-in-title')} />
        {errorObj.errorNotification && (
					<Notification
						text={parse(i18n.t(errorObj.error))}
						className="error"
						dataTestId="banner-error"
					/>
				)}

        <SignInForm successFunc={successFunc} otherRoutes={otherRoutes} errorObj={errorObj} setErrorObj={setErrorObj} />
        <AccessPeiForm successFunc={otherRoutes?.inPersonAccount} errorObj={errorObj} setErrorObj={setErrorObj} />
      </Layout>
    </>
  )
}

export default SignInPage
