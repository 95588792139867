import React, { useEffect, useState } from 'react'
import parse from 'html-react-parser'

import Modal from '../Modal'
import i18n from '../../i18n'
import Button from '../buttons/Button'
import useSessionValidation, { timeToShowSessionModal } from '../../common/hooks/useSessionValidation'
import { useHistory } from 'react-router-dom'
import ROUTES from '../../routes/common/constants'
import SignInModal from './SignInModal'
import { getSessionStorage } from '../../utils'

export const RevalidateSessionModal = ({ onClose }: any) => {
  const countDownModal = timeToShowSessionModal()
  const [countDown, setCountDown] = useState(countDownModal)
  const [openExpiredModal, setOpenExpiredModal] = useState(false)
  const history = useHistory()

  const { checkIDStoreToken } = useSessionValidation()

  const handleContinue = () => {
    checkIDStoreToken()
    onClose()
    setCountDown(countDownModal)
  }

  const handleSignIn = () => {
    history.push(ROUTES.LogoutRoute)
  }

  useEffect(() => {
    let intervalId: any = undefined

    const expiryDate = getSessionStorage('idstr_expiry')
    const expiryMaxDate = getSessionStorage('idstr_expiry_max')

    if (countDown > 0 && expiryDate !== expiryMaxDate) {
      intervalId = setInterval(() => {
        setCountDown((prevSeconds) => prevSeconds - 1)
      }, 1000)
    } else {
      setOpenExpiredModal(true)
      intervalId !== null && clearInterval(intervalId)
    }

    return () => clearInterval(intervalId)
  }, [countDown])

  return (
    <>
      {!openExpiredModal ? (
        <div>
          <div data-testId={'session-modal-title'} className={'title'}>
            {parse(i18n.t('session-modal-extend-title'))}
          </div>
          <div data-testId={'session-modal-desc'}>{parse(i18n.t('session-modal-extend-desc', { seconds: countDown }))}</div>
          <div className="logout-btn">
            <Button className={'btn-exit'} secondary onClick={handleSignIn} text={i18n.t('exit')} dataTestId={'sign-exit'} />
            <Button className={'btn-continue'} onClick={handleContinue} text={i18n.t('continue')} dataTestId={'sign-continue'} />
          </div>
        </div>
      ) : (
        <SignInModal />
      )}
    </>
  )
}

const SessionModal = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { isValidSession } = useSessionValidation()

  useEffect(() => {
    const intervalId = setInterval(() => {
      const isValid = isValidSession()

      if (!isValid) setIsOpen(true)
    }, 30 * 1000)

    return () => {
      clearInterval(intervalId)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Modal toggleModal={() => setIsOpen(true)} canClose={false} isModalOpen={isOpen} className="session-modal">
      <RevalidateSessionModal onClose={() => setIsOpen(false)} />
    </Modal>
  )
}

export default SessionModal
