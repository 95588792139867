import { useHistory } from 'react-router-dom'
import { useContext, updateError } from '../../../Context'
import { useTriggeredIDVHealthCardOnline, useTriggeredIDVUpdateHealthCardOnline, useTriggeredVerification } from '../../../services'
import ROUTES from '../../../routes/common/constants'
import { addSessionStorage } from '../../../utils'

const useIdvVerification = () => {
  const { useVerification: startVerification } = useTriggeredVerification()
  const { requestIDVHealthCardLink } = useTriggeredIDVHealthCardOnline()
  const { requestIDVUpdateHealthCardLink } = useTriggeredIDVUpdateHealthCardOnline()

  const { dispatch } = useContext()
  const history = useHistory()

  const getIDVHealthCardLink = async () => {
    try {
      const { response } = await requestIDVHealthCardLink()
      if (response.isSuccess) {
        window.location.href = response?.data?.link
      }
    } catch (e) {
      dispatch(updateError(true))
    }
  }

  const getIDVHealthCardUpdate = async () => {
    try {
      const { response } = await requestIDVUpdateHealthCardLink()

      if (response.isSuccess) {
        return response?.data
      }
    } catch (e) {
      dispatch(updateError(true))
    }
  }

  const handleVerfication = async () => {
    try {
      const { response } = await startVerification()

      if (response.isSuccess) {
        window.location.href = response?.data?.link
      } else {
        dispatch(updateError(true))
      }
    } catch (e) {
      dispatch(updateError(true))
    }
  }

  const handleStartIdvVerification = (isVerified: boolean, consent: string) => {
    if (isVerified) return

    addSessionStorage('wallet-profile-government', consent)

    history.push(ROUTES.VerifyPeiIdRoute)
  }

  return { handleVerfication, handleStartIdvVerification, getIDVHealthCardLink, getIDVHealthCardUpdate }
}

export default useIdvVerification
