import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useTriggeredProfile } from '../../../../../services'
import { getServices } from '../../../../../wallet/services/ServiceRegistry'

import { errorI, ProfileDataI } from '../../../../../components/ComponentInterface'
import { useContext, updateProfileState, updateError } from '../../../../../Context'
import { VerifyStatus } from '../../../../../common/constants/enum'
import ROUTES from '../../../../../routes/common/constants'

const useProfile = () => {
  const { useProfile: profile } = useTriggeredProfile()
  const { dispatch } = useContext()

  const [isLoading, setIsLoading] = useState(false)
  const [profileData, setProfileData] = useState<ProfileDataI>({})
  const [isVerified, setIsVerified] = useState<boolean>(false)
  const [errorObj, setErrorObj] = useState<errorI>({ error: '', errorNotification: false, errorPassword: false, errorConfirmPassword: false })
  const { storage } = getServices()
  const transactionId = storage.get('cr_token')
  const history = useHistory()

  const getProfile = async () => {
    const error500 = () => setErrorObj({ ...errorObj, error: 'error-500-3' })
    try {
      setIsLoading(true)

      if (!!transactionId) {
        history.push(ROUTES.WalletIdentityVerification)
        return
      }

      const { response, error } = await profile()
      if (response.isSuccess) {
        const { data } = response
        if (data.verified_status) setIsVerified(response.data.verified_status === VerifyStatus.VERIFIED)
        setProfileWithResponseBody(data)
        dispatch(updateProfileState(data))
      } else if (error.isUnauthorizedError) {
        dispatch(updateError(true))
      } else {
        error500()
      }
    } catch (e) {
      error500()
    } finally {
      setIsLoading(false)
    }
  }

  const setProfileWithResponseBody = (data: any) => {
    setProfileData({
      firstName: data.first_name,
      middleName: data.middle_name, // missing in API response
      lastName: data.last_name,
      email: data.email,
      ialLevel: data.ial_level,
      credential: data.credential,
      birthdate: data.birthdate,
      healthCardNumber: data.health_card_number, // missing in API response
      expiryDateHealthCard: data.health_card_expiry, // missing in API response
      driverLicenseNumber: data.driver_license_number, // missing in API response
      expiryDateDriverLicense: data.expiry_date_driver_license, // missing in API response
      serialNumberDriversLicense: data.serial_number_drivers_license, // missing in API response
      voluntaryId: data.voluntary_id, // missing in API response
      expiryDateVoluntaryId: data.expiry_date_voluntary_id, // missing in API response
      needsReVerificationFlow: data.needs_re_verification_flow,
      verifiedStatus: data.verified_status,
      passwordDate: data?.password_date,
      preferredName: data.preferred_name,
      address: data.address,
      phoneNumber: data.phone_number, // missing in API response
    })
  }

  return { getProfile, isLoading, setProfileData, profileData, isVerified, errorObj, setErrorObj, setProfileWithResponseBody }
}

export default useProfile
