import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import parse from 'html-react-parser'

import i18n from '../../../../i18n'

import { Layout, Spinner, Table, TableCard, Title } from '../../../../components'
import iconQuestion from '../../../../asset/icon-table-question.svg'

import ROUTES from '../../../../routes/common/constants'
import KeyDefinitionModal from './components/KeyDefinitionModal'
import useConsentHistory from '../../hooks/useConsentHistory'
import useIsMobile from '../../../../common/hooks/useIsMobile'
import { interpolateRoute } from '../../../../common/utils/interpolateRoute'
import { Card } from '../../../../components/TableCard'

interface DataTable {
  who: string
  id: string
  type: string
  lastActive: string
  clientId: string
}

const WalletConsentHistoryPage = () => {
  const history = useHistory()
  const [keyDefinitionModalOpen, setKeyDefinitionModalOpen] = useState(false)
  const [dataTable, setDataTable] = useState<any>([])
  const { isLoading, org, renderTypeAndLastActive } = useConsentHistory()
  const isMobile = useIsMobile()

  const columns = [
    { label: i18n.t('wallet-consent-history-table-who'), key: 'who' },
    { label: i18n.t('wallet-consent-history-table-type'), key: 'type' },
    { label: i18n.t('wallet-consent-history-table-last-active'), key: 'lastActive' },
  ]

  useEffect(() => {
    const data = org.map((organization) => {
      const { name, id, clients } = organization
      const { type, lastActive } = renderTypeAndLastActive(organization)

      if (clients) {
        const [client] = clients
        return { who: name, id, type, lastActive, clientId: client?.identifier }
      }

      return { who: name, id, type, lastActive, clientId: '' }
    })
    setDataTable(data)
    // eslint-disable-next-line
  }, [org])

  const onClickOrg = (row: any) => {
    const { type, who, clientId } = row
    const link = interpolateRoute(ROUTES.WalletConsentHistoryDetail, { client_id: clientId || who, client_name: who, type })
    history.push(link)
  }

  return (
    <>
      <Layout header footer logout backText={!isLoading ? i18n.t('back') : ''} backBtnCallback={() => history.push(ROUTES.WalletProfile)}>
        {isLoading ? (
          <div className="wallet-app">
            <div className="profile-loading">
              <Spinner dark />
            </div>
          </div>
        ) : (
          <>
            <div className="wallet-consent-history">
              <Title title={i18n.t('wallet-consent-history-title')} dataTestid={'h1'} />
              <div className="subtitle" data-testid={'lead'}>
                {parse(i18n.t('wallet-consent-history-subtitle'))}
              </div>
              <div className="desc">
                <div className="icon-container">
                  <div data-testid={'body'}>{parse(i18n.t('wallet-consent-history-desc'))}</div>
                  <img src={iconQuestion} alt={'questions'} data-testid={'bi:question'} onClick={() => setKeyDefinitionModalOpen(true)} />
                </div>
              </div>
              {!isMobile && (
                <div className="history-table">
                  <Table columns={columns} data={dataTable} isLink onClickRow={onClickOrg} />
                </div>
              )}
              {isMobile && (
                <div className="table-card">
                  <TableCard>
                    {dataTable.map((row: DataTable) => {
                      const { who, type, lastActive, id } = row
                      return (
                        <Card
                          id={id}
                          onClickRow={() => onClickOrg(row)}
                          title={who}
                          type={type}
                          lastActive={i18n.t('wallet-consent-history-table-card-last-active', { date: lastActive })}
                        />
                      )
                    })}
                  </TableCard>
                </div>
              )}
            </div>
            <KeyDefinitionModal onClose={() => setKeyDefinitionModalOpen(false)} isOpen={keyDefinitionModalOpen} />
          </>
        )}
      </Layout>
    </>
  )
}

export default WalletConsentHistoryPage
