import { getConfig } from '../../services'
import apiFetcher from '../apiFetch'
import { updateToken, useContext } from '../../Context'
import { useHistory } from 'react-router-dom'
import ROUTES from '../../routes/common/constants'
import LocalStorage from '../../services/LocalStorage'
import { addSessionStorage, getSessionStorage } from '../../utils'

export const timeBeforeExpireSessionInSeconds = () => {
  const dateString = getSessionStorage('idstr_expiry')

  if (!dateString) return 0

  const expiry = new Date(parseInt(dateString) * 1000)
  const now = new Date()

  const utcExpiry = new Date(
    expiry.getUTCFullYear(),
    expiry.getUTCMonth(),
    expiry.getUTCDate(),
    expiry.getUTCHours(),
    expiry.getUTCMinutes(),
    expiry.getUTCSeconds(),
    expiry.getUTCMilliseconds(),
  )

  const utcDateNow = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds())

  if (utcExpiry.getTime() >= utcDateNow.getTime()) return (utcExpiry.getTime() - utcDateNow.getTime()) / 1000

  return 0
}

export const timeToShowSessionModal = () => {
  const timeToShowSessionModal = getSessionStorage('idstr_session_modal')

  return Math.floor((timeToShowSessionModal && parseInt(timeToShowSessionModal) * 0.2) || 60)
}

export const isValidTime = (dateString: number) => {
  if (!dateString) return false

  const sessionModalTime = timeToShowSessionModal() * 1000
  const expiry = new Date(dateString * 1000 - sessionModalTime)
  const now = new Date()

  const utcExpiry = new Date(
    expiry.getUTCFullYear(),
    expiry.getUTCMonth(),
    expiry.getUTCDate(),
    expiry.getUTCHours(),
    expiry.getUTCMinutes(),
    expiry.getUTCSeconds(),
    expiry.getUTCMilliseconds(),
  )
  const utcDate = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds())

  if (isNaN(expiry.getTime())) {
    return false
  }

  return utcExpiry.getTime() >= utcDate.getTime()
}

/**
 * This hooks is responsible to verify if the idp token is valid
 */
const useSessionValidation = () => {
  const {
    state: { token },
    dispatch,
  } = useContext()

  const history = useHistory()

  const checkIDStoreToken = async (isLoginPage?: boolean) => {
    const { base_url } = (await getConfig())?.data

    if (!token) {
      !isLoginPage && history.push(ROUTES.LogoutRoute)
      return
    }

    try {
      const header = {
        Authorization: `Bearer ${token}`,
      }

      const response = await apiFetcher({
        method: 'POST',
        url: `${base_url}/session/ping`,
        headers: header,
      })
      const { status, data } = response

      if (status === 200) {
        const { expiry, max_expiry } = data
        addSessionStorage('idstr_expiry', expiry)
        addSessionStorage('idstr_expiry_max', max_expiry)
        const timeToShowSessionModal = Math.floor(timeBeforeExpireSessionInSeconds())
        addSessionStorage('idstr_session_modal', timeToShowSessionModal)
        isLoginPage && history.push(ROUTES.WalletProfile)
      } else {
        dispatch(updateToken(''))
        LocalStorage.delete('session')
        history.push(ROUTES.LogoutRoute)
      }
    } catch (e) {
      dispatch(updateToken(''))
      LocalStorage.delete('session')
      history.push(ROUTES.LogoutRoute)
    }
  }

  const isValidSession = () => {
    const expiryDate = getSessionStorage('idstr_expiry')

    if (expiryDate) return isValidTime(parseInt(expiryDate))
  }

  return { checkIDStoreToken, token, isValidSession }
}

export default useSessionValidation
