import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import parse from 'html-react-parser'
import { TextInput, Button, Text } from '../../../../../components'
import { errorI, InputValidateI, ProfileDataI } from '../../../../../components/ComponentInterface'
import { useContext, updateProfileState, updateError } from '../../../../../Context'
import { useTriggeredProfileUpdatePreferredName } from '../../../../../services'
import i18n from '../../../../../i18n'
import {
  nameRegex,
} from '../../../../../utils'

interface PreferredNameChangeFormProps {
  profileData: ProfileDataI
  setProfileData: (data: ProfileDataI) => void
  errorObj: errorI
  setErrorObj: (error: errorI) => void
  validInputs: InputValidateI
  setValidateInputs: (input: InputValidateI) => void
  toggleUpdatePreferredNameForm: () => void
  infoSaved: Boolean
  setInfoSaved: Dispatch<SetStateAction<boolean>>
  dataTestId?: string
}

const PreferredNameChangeForm: FC<PreferredNameChangeFormProps> = ({
  profileData,
  setProfileData,
  errorObj,
  setErrorObj,
  validInputs,
  setValidateInputs,
  toggleUpdatePreferredNameForm,
  infoSaved,
  setInfoSaved,
  dataTestId
}) => {
  const { useUpdateProfileUpdatePreferredName: updateProfilePreferredName, isLoading: updateProfileLoading } = useTriggeredProfileUpdatePreferredName()
  const { dispatch } = useContext()

  const [preferred_name, setPreferredName] = useState(profileData.preferredName)

  const profileInputOnchangeFunc = (e: any) => {
    if (!nameRegex(e.target.value)) return
    setPreferredName(e.target.value.slice(0, 150))
  }

  const updatePreferredName = async () => {
    const { response, error } = await updateProfilePreferredName({
      data: {
        preferred_name: preferred_name || null,
      },
    })

    if (response.isSuccess) {
      setInfoSaved(true)
      setProfileData({
        ...profileData,
        preferredName: response.data.preferred_name,
      })

      setErrorObj({
        ...errorObj,
        errorNotification: false,
        error: '',
      })
      dispatch(updateProfileState(response.data))
      toggleUpdatePreferredNameForm()
    } else if (error.isUnauthorizedError) {
      dispatch(updateError(true))
    } else {
      setErrorObj({
        ...errorObj,
        error: 'error-500-3',
      })
    }
  }

  useEffect(() => {
    setValidateInputs({
      ...validInputs,
      isValidUpdatePreferredNameBtn: true,
    })
    // eslint-disable-next-line
  }, [validInputs.isValidPreferredName])

  return (
    <div data-testid={dataTestId} className={`textbox-form-container-2 ${infoSaved ? 'activated-preferred-name-form' : ''}`}>
      <TextInput
        type="preferred-name-loa3"
        name="preferred_name"
        onChange={profileInputOnchangeFunc}
        value={preferred_name}
        maxLength={150}
        minLength={1}
        label={i18n.t('preferred-name')}
        onValidate={(e) => setValidateInputs({ ...validInputs, isValidPreferredName: e })}
        editClick={toggleUpdatePreferredNameForm}
        isFocused={Boolean(profileData?.preferredName)}
        dataTestId='form-preferred-name'
      />
      <Text className="form-format-description">{parse(i18n.t('preferred-name-format-description'))}</Text>
      <div className="btn-group">
        <Button dataTestId="button-cancel" className={'cancel-btn inverted'} onClick={toggleUpdatePreferredNameForm} text={i18n.t('cancel')} />
        <Button dataTestId="button-save" onClick={updatePreferredName} text={i18n.t('save')} disabled={!validInputs.isValidUpdatePreferredNameBtn} isLoading={updateProfileLoading} />
      </div>
    </div>
  )
}

export default PreferredNameChangeForm
