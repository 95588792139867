import { useState } from 'react'
import { useWallet } from '../../../context/wallet'
import { useWalletFlowHelper } from '../../../context/wallet/WalletFlowHelper'
import { DataSource } from '../../../wallet/services/Datasource'
import { CreatePermission, getMyEnrolledClientPermissionsById } from '../../../wallet'
import { DataSourceAccount } from '../../../wallet/types/datasource'
import { getServices } from '../../../wallet/services/ServiceRegistry'

const useConsent = (id: string) => {
  const [isLoadingPage, setIsLoading] = useState<boolean>(true)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false)
  const [permissions, setPermissions] = useState<CreatePermission[] | undefined>(undefined)
  const [myDsas, setMyDsas] = useState<DataSourceAccount[] | undefined>(undefined)
  const { storage } = getServices()

  const [transactionId] = useState<string>(id!)

  const { getMyDataSourceAccounts } = DataSource()
  const {
    DataSource: { getDataSources, createConnectionUrl },
    Consent: { submitPermissions, createPermissions },
  } = useWallet()

  const {
    ConsentRequestTokenHistory: { get, remove },
  } = useWalletFlowHelper()

  const getSourcesByTransactionId = () => {
    const { requested_resources } = get(transactionId)

    return requested_resources
  }

  const getClientByTransactionId = () => {
    const { client } = get(transactionId)

    return client
  }

  const getScopeDefinitionRequested = () => {
    const userTxSources = getSourcesByTransactionId()

    return userTxSources.map(({ scopes_requested, resource_definition }) => ({ scopes_requested, resource_definition }))
  }

  const scopeKeysRequested = () => {
    const listOfScopes = getScopeDefinitionRequested()

    const listKeyScope = listOfScopes.reduce((accumulator: any, currentValue) => {
      const { scopes_requested, resource_definition } = currentValue

      return [...accumulator, { scopes: scopes_requested, name: resource_definition?.name }]
    }, [])

    return listKeyScope
  }

  const verifyAllPermissionsShared = async () => {
    const { identifier } = getClientByTransactionId()
    const permissionSharedByClient = await getMyEnrolledClientPermissionsById(identifier, { not_disabled: true })

    const permissionByResource = new Map()
    permissionSharedByClient?.forEach((permission) => {
      const {
        resource: { name },
        scopes_granted,
      } = permission

      permissionByResource.set(name, scopes_granted)
    })

    const listOfScopesRequested = scopeKeysRequested()

    const hasAllPermissions = listOfScopesRequested.every((requred: any) => {
      const { name, scopes } = requred

      const permissionShared = permissionByResource.get(name)

      if (!permissionShared) return false

      return scopes?.every(({ scope }: any) => permissionShared.includes(scope))
    })

    return hasAllPermissions
  }

  const containScopeToShowVerificationFlow = async () => {
    const client = scopeKeysRequested()
    const required = ['requires_health_card']

    const containRequiredScope = client.some(({ scopes }: any) => {
      return scopes.some(({ scope }: any) => required.some((require) => require === scope))
    })

    return containRequiredScope
  }

  const getMyDataSources = async () => {
    const requested_resources = getSourcesByTransactionId()

    let has_all_resources = true
    let my_dsas = await getMyDataSourceAccounts()
    let all_ds = await getDataSources()
    setMyDsas(my_dsas)

    requested_resources.forEach((resource) => {
      let has_rr_match = false
      for (let i = 0; i < all_ds.length; i++) {
        const has_resource =
          all_ds[i].data_source.resources?.filter((ds_resource) => {
            // eslint-disable-next-line
            return ds_resource.resource.res_def_id == resource.resource_definition.res_def_id
          }) || []

        if (has_resource.length > 0) {
          for (let j = 0; j < my_dsas.length; j++) {
            // eslint-disable-next-line
            if (my_dsas[j].data_source.data_source_id == all_ds[i].data_source.data_source_id && my_dsas[j].rot.expires_at > new Date().toISOString() && !my_dsas[j].disabled) {
              has_rr_match = true
            }
          }

          if (!has_rr_match) {
            has_all_resources = false

            createConnectionUrl(all_ds[i].data_source.data_source_id, `https://${window.location.hostname}/wallet/rs-callback`).then((result) => {
              window.location.href = result
            })
          }
        }
      }
    })

    if (has_all_resources) {
      setIsLoading(false)
      return
    }
  }

  const handleCreatePermission = async () => {
    const { requested_resources } = get(transactionId)
    const permissions = await createPermissions(requested_resources)
    setPermissions(permissions)
  }

  const handleAgreeButtonClick = () => {
    setIsLoadingSubmit(true)
    submitPermissions(transactionId, permissions!).then(({ redirect_url, error }) => {
      remove(transactionId)
      storage.delete('cr_token')

      if (error) {
        return
      }
      window.location.assign(redirect_url)
    })
  }

  const autoAgreeToConsent = async () => {
    const { requested_resources } = get(transactionId)
    const permissions = await createPermissions(requested_resources)
    submitPermissions(transactionId, permissions!).then(({ redirect_url, error }) => {
      remove(transactionId)
      storage.delete('cr_token')

      if (error) {
        return
      }
      window.location.assign(redirect_url)
    })
  }

  const handleDisagreeButtonClick = () => {
    submitPermissions(transactionId, []).then(({ redirect_url }) => {
      remove(transactionId)
      storage.delete('cr_token')
      window.location.assign(redirect_url)
    })
  }

  const generateListOfScopeName = () => {
    const listScopes = getScopeDefinitionRequested()
    const names = listScopes.map(({ resource_definition }) => resource_definition.name)

    if (names.length === 1) {
      return names[0]
    } else if (names.length === 2) {
      return names.join(' and ')
    } else {
      const lastIndex = names.length - 1
      return names.slice(0, lastIndex).join(', ') + ' and ' + names[lastIndex]
    }
  }

  const generateClientName = () => {
    const { name } = getClientByTransactionId()

    return name
  }

  return {
    generateClientName,
    generateListOfScopeName,
    getScopeDefinitionRequested,
    getSourcesByTransactionId,
    getMyDataSources,
    handleCreatePermission,
    handleAgreeButtonClick,
    autoAgreeToConsent,
    handleDisagreeButtonClick,
    verifyAllPermissionsShared,
    containScopeToShowVerificationFlow,
    isLoadingPage,
    isLoadingSubmit,
    myDsas,
    permissions,
  }
}

export default useConsent
