import React, { useCallback, useState } from 'react'
import { Button, IconListItem, Layout, Text, Title, LinkButton, Modal } from '../../components'
import i18n from '../../i18n'
import parse from 'html-react-parser'
import { ReactComponent as Pin } from '../../asset/icon-pin.svg'
import { ReactComponent as Age } from '../../asset/icon-18.svg'
import { ReactComponent as Card } from '../../asset/icon-card.svg'
import { ReactComponent as Info } from '../../asset/icon-question-grey.svg'
import { PageI } from '../../components/ComponentInterface'
import { getSessionStorage, removeSessionStorage } from '../../utils'
import { useHistory } from 'react-router-dom'
import ROUTES from '../../routes/common/constants'
import { useTriggeredVerification } from '../../services'
import { useContext, updateError } from '../../Context'
import { EIDVBackBtn } from '../../wallet/types/wallet'

const VerifyIdPage = ({ goBackLink, otherRoutes: { skipClick } }: PageI) => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [isModal2Open, setModal2Open] = useState(false)
  const history = useHistory()
  const toggleModal = () => setModalOpen(!isModalOpen)
  const toggleModal2 = () => setModal2Open(!isModal2Open)
  const { useVerification: startVerification } = useTriggeredVerification()
  const { dispatch } = useContext()

  const handleVerificationBtnAction = async () => {
    try {
      const { response } = await startVerification()
      if (response.isSuccess) {
        window.location.href = response?.data?.link
      } else {
        dispatch(updateError(true))
        toggleModal2()
      }
    } catch (e) {
      dispatch(updateError(true))
      toggleModal2()
    }
  }

  const isFromProfilePage = getSessionStorage('wallet-profile-government')

  const handleGoBackLink = useCallback(() => {
    if (!isFromProfilePage) goBackLink()
    else {
      removeSessionStorage('wallet-profile-government')
      if (isFromProfilePage === EIDVBackBtn.PROFILE) {
        history.push(ROUTES.WalletProfile)
      } else {
        history.push(ROUTES.WalletIdentityVerification)
      }
    }
  }, [isFromProfilePage, goBackLink, history])

  return (
    <Layout header footer title={i18n.t('verify-id-title')} backBtnCallback={handleGoBackLink} backText={i18n.t('back')} className="verify-id" logout>
      <Title title={i18n.t('verify-id-title')} />
      <div data-testid="lead">
        <Text>{parse(i18n.t('verify-id-description'))}</Text>
        <Text>{parse(i18n.t('verify-id-description-2'))}</Text>
      </div>
      <IconListItem logo={<Info />}>
        <LinkButton text={i18n.t('verify-id-how-1')} onClick={toggleModal} dataTestId="guidance-what-verified-identity" />
      </IconListItem>
      <IconListItem logo={<Info />}>
        <LinkButton text={i18n.t('verify-id-how-2')} dataTestId="guidance-how-verify-online" />
      </IconListItem>
      <IconListItem logo={<Info />}>
        <LinkButton text={i18n.t('verify-id-how-3')} dataTestId="guidance-how-verify-inperson" />
      </IconListItem>
      <Text className="verify-id-subtitle" dataTestId="subtitle1">
        {parse(i18n.t('verify-id-list-title'))}
      </Text>
      <IconListItem logo={<Pin />}>
        <Text dataTestId="req-resident">{parse(i18n.t('verify-id-list-step-1'))}</Text>
      </IconListItem>
      <IconListItem logo={<Age />}>
        <Text dataTestId="req-age">{parse(i18n.t('verify-id-list-step-2'))}</Text>
      </IconListItem>
      <IconListItem logo={<Card />}>
        <Text className="withLink" dataTestId="req-documents">
          {parse(i18n.t('verify-id-list-step-3'))}
        </Text>
      </IconListItem>
      <div className="verify-id-buttons">
        <Button className="inverted" text={i18n.t('skip-for-now')} onClick={skipClick} dataTestId="Button-secondary" />
        <Button text={i18n.t('continue')} onClick={() => handleVerificationBtnAction()} dataTestId="Button-primary" />
      </div>

      {/* Learn More Modal */}

      <Modal toggleModal={toggleModal} isModalOpen={isModalOpen} className="verify-modal">
        <h2>{`${i18n.t('verify-id-modal-title')}`}</h2>
        <IconListItem>
          <Text>{parse(i18n.t('verify-id-modal-desc-1'))}</Text>
        </IconListItem>
        <IconListItem>
          <Text>{parse(i18n.t('verify-id-modal-desc-2'))}</Text>
        </IconListItem>
        <IconListItem>
          <Text>{parse(i18n.t('verify-id-modal-desc-3'))}</Text>
        </IconListItem>
        <IconListItem>
          <Text>{parse(i18n.t('verify-id-modal-desc-4'))}</Text>
        </IconListItem>
        <IconListItem>
          <Text>{parse(i18n.t('verify-id-modal-desc-5'))}</Text>
        </IconListItem>
        <IconListItem>
          <LinkButton text={i18n.t('verify-id-modal-desc-5')} />
        </IconListItem>
      </Modal>

      {/* Error Modal */}

      <Modal toggleModal={toggleModal2} isModalOpen={isModal2Open} className="verify-modal">
        <h2 className="title" data-testid={'key-title'}>{`${i18n.t('idv-already-in-progress')}`}</h2>
        <p className="description" data-testid={'key-description'}>
          {parse(i18n.t('idv-already-in-progress-description'))}
        </p>
        <Button
          dataTestId="Button-primary"
          className="primary-btn"
          text={i18n.t('return-to-manage-account')}
          onClick={() => {
            history.push(ROUTES.WalletProfile)
          }}
        />
      </Modal>
    </Layout>
  )
}

export default VerifyIdPage
