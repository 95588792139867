import React, { useEffect, useState } from 'react'
import { Button, EmailInput, LinkButton, PasswordInput, Text } from '..'
import parse from 'html-react-parser'
import i18n from '../../i18n'
import { useTriggeredUpdateLogin } from '../../services'
import { InputI, InputValidateI, PageI } from '../ComponentInterface'
import ROUTES from '../../routes/common/constants'

const SignInForm = ({ successFunc, otherRoutes: { createAccount }, errorObj, setErrorObj }: PageI) => {
  const { useUpdateLogin: updateLogin, isLoading } = useTriggeredUpdateLogin()
  const [inputs, setInputs] = useState<InputI>({ email: '', password: '' })
  const [validInputs, setValidateInputs] = useState<InputValidateI>({
    isValidBtn: false,
  })

  useEffect(() => {
    setValidateInputs({
      ...validInputs,
      isValidBtn: Boolean(validInputs.isValidEmail) && Boolean(validInputs.isValidPassword),
    })
    // eslint-disable-next-line
  }, [validInputs.isValidEmail, validInputs.isValidPassword])

  useEffect(() => {
    setErrorObj({
      ...errorObj,
      errorNotification: errorObj.error === 'error-500-1',
      errorPassword: errorObj.error === 'error-login-password',
    })

    // eslint-disable-next-line
  }, [errorObj.error])

  // Check the Enter Button Press
  const keyDownHandler = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()

      if (validInputs.isValidEmail && validInputs.isValidPassword) {
        submitClick()
      }
    }
  }

  const submitClick = async () => {
    const { email, password } = inputs
    const { response, error } = await updateLogin({
      data: { email, password },
    })
    if (response.isSuccess) {
      successFunc && successFunc()
    } else if (error.isForbiddenError || error.isUnauthorizedError || error.isNotFoundError) {
      setErrorObj({ ...errorObj, error: 'error-login-password' })
    } else {
      setErrorObj({ ...errorObj, error: 'error-500-1' })
    }
  }

  return (
    <>
      <form onKeyDown={keyDownHandler} onSubmit={(e) => e.preventDefault()} className="sign-in-form">
        <EmailInput
          onChange={(e: any) => setInputs({ ...inputs, email: e.currentTarget.value })}
          value={inputs.email}
          label={i18n.t('email')}
          onValidate={(e) => {
            setValidateInputs({ ...validInputs, isValidEmail: e })
          }}
          dataTestId="form-email"
          required
          isError={Boolean(errorObj?.errorPassword)}
        />
        <PasswordInput
          minLength={10}
          onChange={(e: any) =>
            setInputs({
              ...inputs,
              password: e.currentTarget.value,
            })
          }
          value={inputs.password}
          label={i18n.t('password')}
          onValidate={(e) => {
            setValidateInputs({
              ...validInputs,
              isValidPassword: e,
            })
          }}
          dataTestId="form-pw"
          required
          isError={Boolean(errorObj?.errorPassword)}
        />
        {errorObj.errorPassword && (
          <Text className="error-msg" dataTestId="error">
            {parse(i18n.t(errorObj.error))}
          </Text>
        )}
        <LinkButton url={`${ROUTES.ForgotPasswordRoute}`} text={i18n.t('forgot-password')} dataTestId="Button-link" />
        <Button onClick={submitClick} text={i18n.t('sign-in')} isLoading={isLoading} dataTestId="Button-primary" />
        <h6>{parse(i18n.t('sign-in-no-account'))}</h6>
        <Button className="inverted" onClick={createAccount} text={i18n.t('create-account')} dataTestId="Button-secondary" />
      </form>
    </>
  )
}

export default SignInForm
