import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { Button, PhoneInput } from '../../../../../components'
import { errorI, InputValidateI, ProfileDataI } from '../../../../../components/ComponentInterface'
import { useContext, updateProfileState, updateError } from '../../../../../Context'
import { useTriggeredProfileUpdatePhoneNumber } from '../../../../../services'
import i18n from '../../../../../i18n'
import {
  numberRegex,
  // phoneNumberRegex,
  removeDashSpaceSpecialChar,
  setSpaceIndex,
} from '../../../../../utils'

interface PhoneNumberChangeFormProps {
  profileData: ProfileDataI
  setProfileData: (data: ProfileDataI) => void
  errorObj: errorI
  setErrorObj: (error: errorI) => void
  validInputs: InputValidateI
  setValidateInputs: (input: InputValidateI) => void
  toggleUpdatePhoneNumberForm: () => void
  infoSaved: Boolean
  setInfoSaved: Dispatch<SetStateAction<boolean>>
  dataTestId?: string
}

const PhoneNumberChangeForm: FC<PhoneNumberChangeFormProps> = ({
  profileData,
  setProfileData,
  errorObj,
  setErrorObj,
  validInputs,
  setValidateInputs,
  toggleUpdatePhoneNumberForm,
  infoSaved,
  setInfoSaved,
  dataTestId,
}) => {
  const { useUpdateProfileUpdatePhoneNumber: updateProfilePhoneNumber, isLoading: updateProfileLoading } = useTriggeredProfileUpdatePhoneNumber()
  const { dispatch } = useContext()

  const [phoneNumber, setPhoneNumber] = useState(profileData?.phoneNumber || '')

  const profileInputOnchangeFunc = (e: any) => {
    if (!numberRegex(removeDashSpaceSpecialChar(e.target.value))) return

    setPhoneNumber(setSpaceIndex(e.target.value, [2, 5], 12))
  }

  const updatePhoneNumber = async () => {
    const phoneNumberWithoutSpaces = phoneNumber?.replace(/\s+/g, '')

    const { response, error } = await updateProfilePhoneNumber({
      data: {
        phone_number: phoneNumberWithoutSpaces || null,
      },
    })

    if (response.isSuccess) {
      setInfoSaved(true)

      setProfileData({
        ...profileData,
        phoneNumber: response.data.phone_number,
      })

      setErrorObj({
        ...errorObj,
        errorNotification: false,
        error: '',
      })
      dispatch(updateProfileState(response.data))
      toggleUpdatePhoneNumberForm()
    } else if (error.isUnauthorizedError) {
      dispatch(updateError(true))
      setInfoSaved(false)
    } else {
      setErrorObj({
        ...errorObj,
        error: 'error-500-3',
      })
      setInfoSaved(false)
    }
  }
  useEffect(() => {
    setValidateInputs({
      ...validInputs,
      isValidUpdatePhoneNumberBtn: Boolean(validInputs.isValidPhoneNumber),
    })
    // eslint-disable-next-line
  }, [validInputs.isValidPhoneNumber])

  return (
    <div data-testid={dataTestId} className={`textbox-form-container-2 ${infoSaved ? 'activated-phone-number-form' : ''}`}>
      <PhoneInput
        type="phone-number-loa3"
        name="phoneNumber"
        onChange={profileInputOnchangeFunc}
        value={phoneNumber}
        maxLength={150}
        label={i18n.t('phone')}
        onValidate={(e) => setValidateInputs({ ...validInputs, isValidPhoneNumber: e })}
        placeholder={i18n.t('placeholder-phone')}
        dataTestId="form-phone-number"
      />
      <div className="btn-group">
        <Button dataTestId="button-cancel" className={'cancel-btn inverted'} onClick={toggleUpdatePhoneNumberForm} text={i18n.t('cancel')} />
        <Button dataTestId="button-save" onClick={updatePhoneNumber} text={i18n.t('save')} isLoading={updateProfileLoading} />
      </div>
    </div>
  )
}

export default PhoneNumberChangeForm
