import React, { useEffect, useState } from 'react'
import ROUTES from '../../routes/common/constants'

import { PasscodeFormI } from '../ComponentInterface'
import { PasscodeInput, LinkButton, Spinner, Text, IconListItem } from '..'
import { useTriggeredUpdateOTP, useTriggeredUpdateOTPResend } from '../../services'
import i18n from '../../i18n'
import parse from 'html-react-parser'
import { useHistory } from 'react-router-dom'
import { ReactComponent as ShowMeIcon } from '../../asset/icon-show-me.svg'
import { ReactComponent as QuestionIcon } from '../../asset/icon-question-green.svg'

const PasscodeForm = ({ successFunc, apiType, twoFaMethod }: PasscodeFormI) => {
  const { useUpdateOTP: updateOtp, isLoading: isLoadingOtp } = useTriggeredUpdateOTP()
  const { useUpdateOTPResend: updateOtpResend, isLoading: isLoadingOtpResend } = useTriggeredUpdateOTPResend()
  const [passcode, setPasscode] = useState('')
  const [isValidPasscode, setIsValidPasscode] = useState(false)
  const [message, setMessage] = useState('')
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [error, setError] = useState(false)
  const history = useHistory()

  useEffect(() => {
    if (!isValidPasscode) return
    const verifyPasscodeOTP = async () => {
      // login account use apiType 'post'
      // create account use apiType 'createAccount'
        const { response, error } = await updateOtp({
          data: { otp: passcode },
        })
        successErrorResponse(response, error)
    }
    verifyPasscodeOTP()
    // eslint-disable-next-line
  }, [isValidPasscode])

  useEffect(() => {
    const messageText = ['otp-resend-fail', 'otp-generic-error', 'otp-enter-password-error', 'otp-too-many-attempts-error', 'otp-expired-error']
    setError(messageText.some((text) => message.includes(text)))
  }, [message])

  const successErrorResponse = (response: any, error: any) => {
    if (response.isSuccess) {
      setIsRedirecting(true)
      successFunc && successFunc()
    } else if (error.isNotFoundError) {
      setMessage('otp-enter-password-error')
      setIsValidPasscode(false)
    } else if (error.isTooManyAttemptError) {
      setMessage('otp-too-many-attempts-error')
    } else if (error.isSessionTimedOutError) {
      setMessage('otp-expired-error')
      setIsValidPasscode(false)
    } else if (error.isUnauthorizedError) {
      history.push(ROUTES.LogoutRoute)
    } else {
      setMessage('otp-generic-error')
      setIsValidPasscode(false)
    }
  }

  const resendClick = async () => {
    const { response } = await updateOtpResend()
    if (response.isSuccess) {
      setMessage('otp-resend-success')
    } else {
      setMessage('otp-resend-fail')
    }
  }

	return (
		<>
			{isLoadingOtp ||
			isLoadingOtpResend ||
			isRedirecting ? (
				<Spinner dark />
			) : (
				<>
					<PasscodeInput
						onChange={(e: any) => {
							setPasscode(e);
						}}
						onValidate={setIsValidPasscode}
						label={i18n.t("one-time-password-title")}
						className={error ? "error" : ""}
						code={message ? passcode : ""}
					/>
					{error && (
						<Text className="error-msg" dataTestId="error">
							{parse(i18n.t(message))}
						</Text>
					)}
					{twoFaMethod === "TOTP" ? (
						<div className="links-container">
							<IconListItem
								logo={<ShowMeIcon />}
								dataTestId="show-how"
							>
								{/* TODO UPDATE THE REDIRECT URL TO SHOW PAGE */}
								<LinkButton
									text={i18n.t(
										"setup-authenticator-app-show-me-link"
									)}
									onClick={() => {}}
									dataTestId="Show-how"
								/>
							</IconListItem>
							<IconListItem logo={<QuestionIcon />}>
								{/* TODO UPDATE THE REDIRECT URL TO Can't access PAGE */}
								<LinkButton
									text={i18n.t(
										"one-time-password-totp-cant-access"
									)}
									onClick={() => {}}
									dataTestId="cant-access"
								/>
							</IconListItem>
						</div>
					) : (
						<>
							{message === "otp-resend-success" && !error && (
								<Text
									className="code-sent"
									dataTestId="body-resend"
								>
									{i18n.t(message)}
								</Text>
							)}
							<LinkButton
								onClick={resendClick}
								text={i18n.t(
									"one-time-password-resend-passcode"
								)}
								dataTestId="Button-link"
							/>
						</>
					)}
				</>
			)}
		</>
	);
};

export default PasscodeForm
