import React, { FC, useEffect, useState } from 'react'
import parse from 'html-react-parser'

import { Layout, Title, Text, Spinner } from '../../../../components'
import i18n from '../../../../i18n'

import { InputValidateI } from '../../../../components/ComponentInterface'

import { AccountHistory, AccountInformation, AdditionalInformation, DeleteAccount, ProfileCardInfo, ProfileInformation } from './components'
import useProfile from './hooks/useProfile'
import useAccountInformation from './hooks/useAccountInformation'
import { useContext } from '../../../../Context'

const WalletProfilePage: FC = () => {
  const { isLoading, isVerified, profileData, setProfileData, getProfile, errorObj, setErrorObj, setProfileWithResponseBody } = useProfile()
  const [validInputs, setValidateInputs] = useState<InputValidateI>({ isValidUpdateNameBtn: false, isValidPasswordBtn: false })
  const { authMethod, getAuthMethod } = useAccountInformation()

  useEffect(() => {
    getProfile()
    getAuthMethod()
    // eslint-disable-next-line
  }, [])

  const {
    state: {
      config: { pei_portal_link },
    },
  } = useContext()

  const handleGoBack = () => {
    if (!!pei_portal_link && !!window) {
      window.location.assign(pei_portal_link)
    } else {
      console.error('Error when trying to go to PEI portal link')
    }
  }

  return (
    <Layout header footer logout backText={!isLoading ? i18n.t('back') : ''} backBtnCallback={handleGoBack}>
      {isLoading ? (
        <div className="wallet-app">
          <div className="profile-loading">
            <Spinner dark />
          </div>
        </div>
      ) : (
        <div className="wallet-app">
          <Title title={i18n.t('wallet-profile-title')} />
          <div className="subtitle">
            <Text dataTestId="h3-personal-info">{parse(i18n.t('wallet-profile-personal-information-title'))}</Text>
          </div>
          <div className="wallet-profile-page-layout">
            <div className="wallet-profile-data">
              <ProfileInformation
                profileData={profileData}
                setProfileData={setProfileData}
                setProfileWithResponseBody={setProfileWithResponseBody}
                errorObj={errorObj}
                setErrorObj={setErrorObj}
                validInputs={validInputs}
                setValidateInputs={setValidateInputs}
                isVerified={isVerified}
              />
              <AdditionalInformation profileData={profileData} isVerified={isVerified} setProfileData={setProfileWithResponseBody} />
              <AccountInformation
                profileData={profileData}
                setProfileData={setProfileData}
                errorObj={errorObj}
                setErrorObj={setErrorObj}
                validInputs={validInputs}
                setValidateInputs={setValidateInputs}
                isVerified={isVerified}
                authMethod={authMethod}
              />
              <AccountHistory />
            </div>
            <ProfileCardInfo />
          </div>
          <DeleteAccount />
        </div>
      )}
    </Layout>
  )
}

export default WalletProfilePage
