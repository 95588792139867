import React from 'react'
import parse from 'html-react-parser'
import i18n from '../../../../../i18n'

const DeleteAccount = () => {
  return (
    <div className="profile-information-session delete-account">
      <div className="divider"></div>
      <div className="delete-account">
        <div className="description">
          <span>
            {parse(i18n.t('wallet-profile-delete-account'))}
            <a href="mailto: mypei@gov.pe.ca" target="_blank" rel="noreferrer">
              {parse(i18n.t('wallet-profile-delete-account-email'))}
            </a>
          </span>
        </div>
      </div>
    </div>
  )
}

export default DeleteAccount
