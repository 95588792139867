import React from 'react'
import parse from 'html-react-parser'
import { Modal } from '../../../../../../components'
import i18n from '../../../../../../i18n'

interface Props {
  onClose: () => void
  isOpen: boolean
}

const EmailModal = ({ onClose, isOpen }: Props) => {
  return (
    <Modal toggleModal={onClose} isModalOpen={isOpen} className="wallet-history-key-definition">
      <div data-testid={'key-definition-modal'}>
        <h4 className="title" data-testid={'key-title'}>
          {parse(i18n.t('wallet-profile-modal-email-title'))}
        </h4>
        <div className="subtitle" data-testid={'key-subtitle'}>
          {parse(i18n.t('wallet-profile-modal-email-desc'))}
        </div>
      </div>
    </Modal>
  )
}

export default EmailModal
