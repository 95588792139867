import React, { FC, Dispatch, SetStateAction, useEffect, useMemo } from 'react'
import parse from 'html-react-parser'

import { ReactComponent as Warning } from '../../../../../asset/icon-warning.svg'
import { useContext, updateError } from '../../../../../Context'

import { Button, PasswordInput, Text, Notification } from '../../../../../components'
// import useErrorHandling from '../hooks/useErrorHandling'
import { useTriggeredProfileUpdateChangePassword } from '../../../../../services'
import i18n from '../../../../../i18n'
import { errorI, InputValidateI, ProfileDataI } from '../../../../../components/ComponentInterface'

interface PasswordChangeFormProps {
  profileData: ProfileDataI
  setProfileData: (data: ProfileDataI) => void
  errorObj: errorI
  setErrorObj: (error: errorI) => void
  validInputs: InputValidateI
  setValidateInputs: (input: InputValidateI) => void
  toggleUpdatePasswordForm: () => void
  setInfoSaved: Dispatch<SetStateAction<boolean>>
  setPasswordUpdated: Dispatch<SetStateAction<boolean>>
  dataTestId?: string
}

const PasswordChangeForm: FC<PasswordChangeFormProps> = ({
  profileData,
  setProfileData,
  errorObj,
  setErrorObj,
  validInputs,
  setValidateInputs,
  toggleUpdatePasswordForm,
  setInfoSaved,
  setPasswordUpdated,
  dataTestId,
}) => {
  const { useUpdateProfileChangePassword: updateProfileChangePassword, isLoading: changePasswordLoading } = useTriggeredProfileUpdateChangePassword()
  const { dispatch } = useContext()

  const profileInputOnChangeFunc = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedProfileData = {
      ...profileData,
      [e.target.name]: e.target.value.slice(0, 150),
    }

    setProfileData(updatedProfileData)
  }

  const isPasswordMatch = profileData.newPassword === profileData.confirmPassword

  const cancelUpdatePassword = () => {
    //close the password form
    toggleUpdatePasswordForm()
    //reset the password form
    setProfileData({
      ...profileData,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    })
    //reset the error messages
    setErrorObj({ errorNotification: false, errorConfirmPassword: false, errorPassword: false, error: '' })
  }

  const updatePassword = async () => {
    if (profileData.newPassword === profileData.confirmPassword) {
      const { response, error } = await updateProfileChangePassword({
        data: {
          new_password: profileData.newPassword,
          current_password: profileData.currentPassword,
        },
      })

      if (response.isSuccess) {
        setPasswordUpdated(true)
        toggleUpdatePasswordForm()
        setInfoSaved(true)

        setProfileData({
          ...profileData,
          currentPassword: '',
          newPassword: '',
          confirmPassword: '',
        })

        setErrorObj({ errorNotification: false, errorConfirmPassword: false, errorPassword: false, error: '' })
      } else if (error.isNotFoundError) {
        dispatch(updateError(true))
        setErrorObj({ ...errorObj, error: 'error-profile-information-incorrect-password' })
        setInfoSaved(false)
      } else if (error.isInvalidError) {
        dispatch(updateError(true))
        setErrorObj({ ...errorObj, error: 'error-profile-information-password-requirement' })
        setInfoSaved(false)
      } else {
        setErrorObj({
          ...errorObj,
          error: 'error-500-3',
        })
        setInfoSaved(false)
      }
    } else {
      setErrorObj({ ...errorObj, errorConfirmPassword: true })
    }
  }

  useEffect(() => {
    setValidateInputs({
      ...validInputs,
      isValidUpdatePasswordBtn:
        Boolean(validInputs.isValidPassword) &&
        Boolean(validInputs.isValidNewPassword) &&
        Boolean(validInputs.isValidConfirmPassword) &&
        profileData.newPassword === profileData.confirmPassword,
    })
    // eslint-disable-next-line
  }, [
    validInputs.isValidPassword,
    validInputs.isValidNewPassword,
    validInputs.isValidConfirmPassword,
    profileData.currentPassword,
    profileData.newPassword,
    profileData.confirmPassword,
  ])
  const canSubmitPasswordChange = useMemo(
    () => !!profileData?.currentPassword && !!profileData?.newPassword && !!profileData?.confirmPassword && profileData.newPassword === profileData.confirmPassword,
    [profileData],
  )

  return (
    <div className="textbox-form-container" data-testid={dataTestId}>
      {errorObj.errorNotification && <Notification icon={<Warning />} text={parse(i18n.t(errorObj.error))} className="error" dataTestId="banner-error" />}
      <PasswordInput
        name="currentPassword"
        minLength={12}
        onChange={profileInputOnChangeFunc}
        value={profileData?.currentPassword}
        label={i18n.t('profile-information-current-password')}
        required
        isFocused={Boolean(profileData?.currentPassword)}
        isError={errorObj.error === 'error-profile-information-incorrect-password'}
        onValidate={(e) => setValidateInputs({ ...validInputs, isValidPassword: e })}
      />
      <PasswordInput
        name="newPassword"
        minLength={12}
        onChange={profileInputOnChangeFunc}
        value={profileData?.newPassword}
        label={i18n.t('profile-information-create-new-password')}
        onValidate={(e) => setValidateInputs({ ...validInputs, isValidNewPassword: e })}
        description={parse(i18n.t('password-creation-description'))}
        required
        isFocused={Boolean(profileData?.newPassword)}
        isError={errorObj.error === 'error-profile-information-password-requirement' || !isPasswordMatch}
        // handleOnBlurEvent={handleOnBlurEvent}
        dataTestId="form-pw-new"
      />
      <PasswordInput
        name="confirmPassword"
        minLength={12}
        onChange={profileInputOnChangeFunc}
        value={profileData?.confirmPassword}
        label={i18n.t('profile-information-confirm-new-password')}
        onValidate={(e) => setValidateInputs({ ...validInputs, isValidConfirmPassword: e })}
        required
        isFocused={Boolean(profileData?.confirmPassword)}
        isError={errorObj.error === 'error-profile-information-password-requirement' || !isPasswordMatch}
        // handleOnBlurEvent={handleOnBlurEvent}
        dataTestId="form-pw-confirm"
      />
      {!isPasswordMatch && (
        <Text className="error" dataTestId="error">
          {parse(i18n.t('password-do-not-match'))}
        </Text>
      )}
      <div className="btn-group">
        <Button dataTestId="button-cancel" className={'cancel-btn inverted'} onClick={cancelUpdatePassword} text={i18n.t('cancel')} />
        <Button dataTestId="button-save" disabled={!canSubmitPasswordChange} onClick={updatePassword} text={i18n.t('save')} isLoading={changePasswordLoading} />
      </div>
    </div>
  )
}

export default PasswordChangeForm
