import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import i18n from '../../../../../i18n'

import { useContext, updateProfileState, updateError } from '../../../../../Context'
import { useTriggeredProfileUpdateAddress } from '../../../../../services'

import { TextInput, Button } from '../../../../../components'
import { errorI, InputValidateI, ProfileDataI } from '../../../../../components/ComponentInterface'
import { postalCodeRegex, setSpaceIndex } from '../../../../../utils'

interface AddressChangeFormProps {
  profileData: ProfileDataI
  setProfileData: (data: ProfileDataI) => void
  errorObj: errorI
  setErrorObj: (error: errorI) => void
  validInputs: InputValidateI
  setValidateInputs: (input: InputValidateI) => void
  toggleUpdateAddressForm: () => void
  infoSaved: Boolean
  setInfoSaved: Dispatch<SetStateAction<boolean>>
  dataTestId?: string
}

const AddressChangeForm: FC<AddressChangeFormProps> = ({
  profileData,
  setProfileData,
  errorObj,
  setErrorObj,
  validInputs,
  setValidateInputs,
  toggleUpdateAddressForm,
  infoSaved,
  setInfoSaved,
  dataTestId,
}) => {
  const { useUpdateProfileUpdateAddress: updateProfileAddress, isLoading: updateProfileLoading } = useTriggeredProfileUpdateAddress()
  const { dispatch } = useContext()

  const [address, setAddress] = useState({
    street1: profileData.address?.street1,
    street2: profileData?.address?.street2,
    city: profileData?.address?.city,
    region: profileData?.address?.region || 'PE',
    country: profileData?.address?.country || 'CA',
    postal_code: profileData?.address?.postal_code,
  })

  const profileInputOnchangeFunc = (e: any) => {
    const updatedProfileData = {
      ...address,
      [e.target.name]: e.target.value.slice(0, 150),
    }

    setAddress(updatedProfileData)
  }

  const profilePostalInputOnchangeFunc = (e: any) => {
    if (!postalCodeRegex(e.target.value)) return

    const updatedProfileData = {
      ...address,
      [e.target.name]: setSpaceIndex(e.target.value, [2], 7),
    }
    setAddress(updatedProfileData)
    // setBirthdate(setSlashIndex(e.target.value, [3, 5], 10))
  }

  const updateAddress = async () => {
    const { street1, street2, city, postal_code } = address

    const postalCodeWithoutSpaces = postal_code ? postal_code.replace(/\s+/g, '') : ''

    const { response, error } = await updateProfileAddress({
      data: {
        street1: street1 || null,
        street2: street2 || null,
        city: city || null,
        region: 'PE',
        country: 'CA',
        postal_code: postalCodeWithoutSpaces || null,
      },
    })

    if (response.isSuccess) {
      setInfoSaved(true)
      setProfileData({
        ...profileData,
        address: {
          street1: response.data.address.street1,
          street2: response.data.address.street2 || null,
          city: response.data.address.city,
          region: 'PE',
          country: 'CA',
          postal_code: response.data.address.postal_code,
        },
      })

      setErrorObj({
        ...errorObj,
        errorNotification: false,
        error: '',
      })
      dispatch(updateProfileState(response.data))
      toggleUpdateAddressForm()
    } else if (error.isUnauthorizedError) {
      dispatch(updateError(true))
      setInfoSaved(false)
    } else {
      setErrorObj({
        ...errorObj,
        error: 'error-500-3',
      })
      setInfoSaved(false)
    }
  }

  useEffect(() => {
    setValidateInputs({
      ...validInputs,
      isValidUpdateAddressBtn: Boolean(validInputs.isValidStreet1) && Boolean(validInputs.isValidCity) && Boolean(validInputs.isValidPostalCode),
    })
    // eslint-disable-next-line
  }, [validInputs.isValidUpdateAddressBtn, address])

  return (
    <div data-testid={dataTestId} className={`textbox-form-container-2 ${infoSaved ? 'activated-address-form' : ''}`}>
      <TextInput
        type="address-street1-loa3"
        name="street1"
        onChange={profileInputOnchangeFunc}
        value={address.street1}
        maxLength={150}
        minLength={1}
        label={i18n.t('wallet-profile-personal-information-street1')}
        onValidate={(e) => setValidateInputs({ ...validInputs, isValidStreet1: e })}
        placeholder={i18n.t('placeholder-street-address-1')}
        isFocused={Boolean(profileData?.address?.street1)}
        dataTestId="form-address1"
      />
      <TextInput
        type="address-street2-loa3"
        name="street2"
        onChange={profileInputOnchangeFunc}
        value={address.street2}
        maxLength={150}
        minLength={1}
        label={i18n.t('wallet-profile-personal-information-street2')}
        onValidate={(e) => setValidateInputs({ ...validInputs, isValidStreet2: e })}
        placeholder={i18n.t('placeholder-street-address-2')}
        isFocused={Boolean(profileData?.address?.street2)}
        dataTestId="form-address2"
      />
      <TextInput
        type="address-city-loa3"
        name="city"
        onChange={profileInputOnchangeFunc}
        value={address.city}
        maxLength={150}
        minLength={1}
        label={i18n.t('wallet-profile-personal-information-city')}
        onValidate={(e) => setValidateInputs({ ...validInputs, isValidCity: e })}
        placeholder={i18n.t('placeholder-city')}
        isFocused={Boolean(profileData?.address?.city)}
        dataTestId="form-address3"
      />
      <TextInput
        type="address-postal-code-loa3"
        name="postal_code"
        onChange={profilePostalInputOnchangeFunc}
        value={address.postal_code}
        maxLength={150}
        minLength={1}
        label={i18n.t('wallet-profile-personal-information-postal-code')}
        onValidate={(e) => setValidateInputs({ ...validInputs, isValidPostalCode: e })}
        placeholder={i18n.t('placeholder-postal-code')}
        isFocused={Boolean(profileData?.address?.postal_code)}
        dataTestId="form-address4"
      />
      <div className="btn-group">
        <Button dataTestId="button-cancel" className={'cancel-btn inverted'} onClick={toggleUpdateAddressForm} text={i18n.t('cancel')} />
        <Button dataTestId="button-save" onClick={updateAddress} text={i18n.t('save')} isLoading={updateProfileLoading} />
      </div>
    </div>
  )
}

export default AddressChangeForm
