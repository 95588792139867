import React from 'react'
import parse from 'html-react-parser'
import { Modal, Button } from '../../../../../../components'
import i18n from '../../../../../../i18n'

interface Props {
  onClose: () => void
  isOpen: boolean
  updateAction: () => void
}

const UpdateSWATModal = ({ onClose, isOpen, updateAction }: Props) => {
  return (
    <Modal toggleModal={onClose} isModalOpen={isOpen} className="wallet-history-key-definition">
      <div data-testid={'update-verified-personal-info-modal'}>
        <h4 className="title" data-testid={'key-title'}>
          {parse(i18n.t('update-verified-personal-info-title'))}
        </h4>
        <div className="subtitle" data-testid={'Body'}>
          <p>{parse(i18n.t('update-verified-personal-info-body-1'))}</p>
          <br />
          <p>{parse(i18n.t('update-verified-personal-info-body-2'))}</p>
          <br />
          <p>{parse(i18n.t('update-verified-personal-info-body-3'))}</p>
        </div>
        <div className="btn-group">
          <Button dataTestId="Button-secondary" className={'cancel-btn inverted'} onClick={onClose} text={i18n.t('cancel')} />
          <Button dataTestId="Buttom-primary" onClick={updateAction} text={i18n.t('update-now')} />
        </div>
      </div>
    </Modal>
  )
}

export default UpdateSWATModal
